import { useState } from "react";
import styled from "styled-components";

import { IconButton, ButtonColorScheme } from "@happeouikit/buttons-ds3";
import { IconCopy } from "@happeouikit/icons";
import { Tooltip } from "@happeouikit/tooltip";

type CopyToClipboardButtonProps = {
  clipboardContent?: string;
  clipboardCopyLabel?: string;
  clipboardCopiedLabel?: string;
  icon?: React.ReactElement;
  colorScheme?: ButtonColorScheme;
  onSaveToClipboard?: (value: string) => void;
  hideSuccessTooltip?: boolean;
};

/**
 * Will copy text into clipboard (doesn't include any special formatting/html)
 */
const CopyToClipboardButton = ({
  clipboardContent,
  clipboardCopyLabel = "Copy to clipboard",
  clipboardCopiedLabel = "Copied!",
  icon,
  colorScheme,
  onSaveToClipboard = () => undefined,
  hideSuccessTooltip,
}: CopyToClipboardButtonProps) => {
  const [showsSuccess, setShowsSuccess] = useState(false);
  const [uniqueTooltipId] = useState(
    `copy-to-clipboard-tooltip-${Math.random()}`
  );

  const saveOnClipboard = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (navigator.clipboard && clipboardContent) {
      navigator.clipboard.writeText(clipboardContent);
    }
    onSaveToClipboard?.(clipboardContent || "");
    if (!hideSuccessTooltip) setShowsSuccess(true);
  };

  const onTooltipHide = () => {
    setShowsSuccess(false);
  };

  return (
    <WrapperDiv>
      <Tooltip id={uniqueTooltipId} afterHide={onTooltipHide} />
      <IconButton
        size="sm"
        variant="tertiary"
        colorScheme={colorScheme || "active"}
        icon={icon || <IconCopy />}
        aria-label={clipboardCopyLabel}
        data-testid="clipboard-button"
        onClick={saveOnClipboard}
        defaultTooltip={false}
        data-tooltip-id={uniqueTooltipId}
        data-tooltip-class-name="small"
        data-tooltip-variant={showsSuccess ? "success" : "dark"}
        data-tooltip-content={
          showsSuccess ? clipboardCopiedLabel : clipboardCopyLabel
        }
      />
    </WrapperDiv>
  );
};

const WrapperDiv = styled.div`
  display: flex;
  align-items: center;
`;

export default CopyToClipboardButton;
