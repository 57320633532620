import React from "react";
import styled from "styled-components";
import { TypographyProps } from "./Typography.types";
import { defaultColor, variantMap, sansFamily } from "./constants";

type WithChildren<T> = T &
  (
    | { children?: React.ReactNode }
    | { dangerouslySetInnerHTML: { __html: string } }
  );

export const Typography = ({
  variant = "body-lg",
  fontWeight: fw = "regular",
  component,
  bold = false,
  uppercase = false,
  color = defaultColor,
  style,
  children,
  ...props
}: WithChildren<TypographyProps>) => {
  const variantToUse = variantMap[variant] ? variant : "body-lg";
  const {
    fontWeight: defaultFontWeight,
    component: defaultComponent,
  } = variantMap[variantToUse];
  const fwToUse = bold ? "bold" : fw;
  const fontWeight = defaultFontWeight[fwToUse] || defaultFontWeight.regular;
  return (
    <BaseTypography
      $weight={fontWeight}
      $color={color}
      $variant={variant}
      $uppercase={uppercase}
      as={component || defaultComponent}
      style={style}
      {...props}
    >
      {children}
    </BaseTypography>
  );
};

const BaseTypography: React.FC<{
  as: React.ElementType;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  $variant: string;
  $weight: string;
  $color: string;
  $uppercase: boolean;
}> = styled.p<{
  $variant: string;
  $weight: string;
  $color: string;
  $uppercase: boolean;
}>`
  -webkit-font-smoothing: antialiased;
  ${({ $variant, $weight, $color, $uppercase }) => ({
    ...variantMap[$variant].style,
    fontWeight: $weight,
    color: $color || defaultColor,
    textTransform: $uppercase ? "uppercase" : "inherit",
    fontFamily: sansFamily,
  })};
`;

export const TitleXl = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="title-xl" />
))``;
export const TitleLg = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="title-lg" />
))``;
export const TitleMd = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="title-md" />
))``;
export const TitleSm = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="title-sm" />
))``;
export const SubtitleLg = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="subtitle-lg" />
))``;
export const SubtitleSm = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="subtitle-sm" />
))``;
export const BodyLg = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="body-lg" />
))``;
export const BodySm = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="body-sm" />
))``;
export const CaptionLg = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="caption-lg" />
))``;
export const CaptionLgLoose = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="caption-lg-loose" />
))``;
export const CaptionSm = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="caption-sm" />
))``;
export const CodeLg = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="code-lg" />
))``;
export const CodeMd = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="code-md" />
))``;
export const CodeSm = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="code-sm" />
))``;
export const GlyphXl = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="glyph-xl" />
))``;
export const GlyphLg = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="glyph-lg" />
))``;
export const GlyphMd = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="glyph-md" />
))``;
export const GlyphSm = styled((props: WithChildren<TypographyProps>) => (
  <Typography {...props} variant="glyph-sm" />
))``;
