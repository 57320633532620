import React from "react";
import { ChromePicker } from "react-color";

type BasicColorPickerType = {
  color: string;
  handleClose: () => void;
  handleChange: (color: { hex: string }) => void;
  disableAlpha: boolean;
  pickerZIndex: number;
};

const BasicColorPicker = ({
  color,
  handleClose,
  handleChange,
  disableAlpha,
  pickerZIndex,
}: BasicColorPickerType) => {
  const pickerEl = React.useRef<HTMLDivElement>(null);

  const handleClick = React.useCallback(
    (e: MouseEvent) => {
      if (pickerEl.current && !pickerEl.current.contains(e.target as Node)) {
        handleClose();
      }
    },
    [handleClose]
  );

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick]);

  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        zIndex: `${pickerZIndex ?? 1}`,
      }}
    >
      <div ref={pickerEl}>
        <ChromePicker
          onChange={handleChange}
          color={color}
          disableAlpha={disableAlpha}
        />
      </div>
    </div>
  );
};

export default BasicColorPicker;
