import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { TextEta } from "@happeouikit/typography";
import { gray06, white, alert } from "@happeouikit/colors";
import { Input } from "@happeouikit/form-elements";
import BasicColorPicker from "./BasicColorPicker";
import { ColorPickerProps } from "./ColorPicker.types";

const ColorPicker = ({
  label,
  value,
  onValueChanged,
  disableAlpha,
  disabled,
  pickerZIndex,
}: ColorPickerProps) => {
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const pickedColor = value ?? "#000000";

  return (
    <div ref={containerRef}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledInputColor
        value={pickedColor.toUpperCase()}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onValueChanged(e.target?.value)
        }
        onClick={() => setShowColorPicker(true)}
        icon={() => <ColorDisplay color={pickedColor} />}
        disabled={disabled}
      />
      {showColorPicker &&
        ReactDOM.createPortal(
          <BasicColorPicker
            disableAlpha={disableAlpha === undefined ? true : disableAlpha}
            color={pickedColor}
            handleClose={() => setShowColorPicker(false)}
            handleChange={(color: { hex: string }) => onValueChanged(color.hex)}
            pickerZIndex={pickerZIndex ?? 1}
          />,
          containerRef.current as Element
        )}
    </div>
  );
};

const StyledLabel = styled(TextEta)`
  margin-bottom: 8px;
  font-weight: 500;
`;

const StyledInputColor = styled(Input)`
  padding-left: 10px;
`;

const ColorDisplay = styled.span`
  border: 1px solid ${({ color }) => color || gray06};
  background-color: ${({ color }) => color || white};
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  top: 12px;

  ${({ color }) =>
    !color &&
    `
  :after {
    content: "/";
    color: ${alert};
    position: relative;
    left: 5px;
    bottom: 4px;
    font-size: 22px;
    width: 12px;
    transform: rotate(17deg);
    display: block;
  }
`}
`;

export default ColorPicker;
