export enum MODAL_STATES {
  alert = "alert",
  delete = "delete",
  success = "success",
  info = "info",
  warning = "warn",
}

export interface ModalProps {
  isOpen: boolean;
  closeOnClickOutside?: boolean;
  children: React.ReactNode;
  headerText?: string | React.ReactNode | Element;
  close: () => void;
  id?: string;
  width?: string;
  top?: string;
  footer?: boolean;
  okCallback?: () => void;
  okText?: string;
  cancelText?: string;
  iconCloseText?: string;
  iconClose?: boolean;
  footerComponent?: React.ReactNode;
  disabled?: boolean;
  overlayClassName?: string;
  overlayStyle?: React.CSSProperties;
  closeOnEsc?: boolean;
  reactModalProps?: Record<string, unknown>;
  tertiaryButton?: React.ReactNode;
  style?: React.CSSProperties;
}

export interface ConfirmModalProps {
  isOpen: boolean;
  close: () => void;
  bodyText: string;
  header: React.ReactNode;
  okCb: () => void;
  id?: string;
  hideCancel?: boolean;
  state: MODAL_STATES;
  btnDisabled?: boolean;
  okText: string;
  cancelText: string;
  width?: string;
  closeOnClickOutside?: boolean;
  closeOnEsc?: boolean;
  children?: React.ReactNode;
  dataTracker?: string;
}
