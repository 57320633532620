import {
  forwardRef,
  useMemo,
  useRef,
  useEffect,
  useImperativeHandle,
  createElement,
} from "react";
import { TextZeta } from "@happeouikit/typography";
import styled from "styled-components";
import { Popover } from "@happeouikit/layout";
// @ts-ignore
import { hooks } from "@happeo/react-core";
import { Container, ActionRow } from "./styles";

import { getAnchorPosition } from "./utils";
import { TextEta } from "@happeouikit/typography";
import { ButtonActionItem } from "./ActionsMenu.types";

interface DropdownActionMenuProps {
  dropdownId: string;
  actions: {
    name: string | React.ReactNode;
    callback?: () => void;
    url?: string;
    type?: string;
    icon?: () => React.ReactNode;
    dataTracker?: string;
    items?: Array<ButtonActionItem>;
  }[];
  selectedActionName?: string;
  onClose: () => void;
  menuPosition: string;
  anchor: any;
}

const DropdownActionMenu = (
  {
    dropdownId,
    actions,
    selectedActionName,
    onClose,
    menuPosition,
    anchor,
  }: DropdownActionMenuProps,
  ref: any
) => {
  const dropdownRef = useRef<HTMLUListElement>(null);
  const actionsRef = useRef([]);
  const popoverRef = useRef(null);
  const focusFirstElement = () => {
    // @ts-ignore - focus is a function
    actionsRef.current[0]?.focus();
  };

  hooks.useOnClickOutside(popoverRef, onClose);

  const focusLastElement = () => {
    const lastActionsRefIndex = actionsRef.current?.length - 1;
    // @ts-ignore - focus is a function
    actionsRef.current[lastActionsRefIndex]?.focus();
  };
  const focusOnParent = () => {
    // @ts-ignore - focus is a function
    dropdownRef.current?.previousElementSibling?.focus();
  };

  // Backwards compatibility when using just left right position
  const anchorPosition = useMemo(() => getAnchorPosition(menuPosition), [
    menuPosition,
  ]);

  useEffect(() => {
    actionsRef.current = actionsRef.current?.slice(0, actions.length);
  }, [actions]);

  useImperativeHandle(ref, () => ({
    focusFirstElement,
    focusOnParent,
  }));

  const close = (cb: any) => {
    onClose();
    if (cb) {
      cb();
    }
  };

  const onKeyDown = (event: any, action: any, i: number) => {
    const { key } = event;
    const { callback, type, url } = action;

    if (key.match(/enter|spacebar|\W{1}/i)) {
      if (type === "href" && url) {
        // @ts-ignore - click is a function
        actionsRef.current[i]?.firstElementChild.click();
        return;
      }

      event.preventDefault();
      close(callback);
      focusOnParent();
    } else if (key.match(/arrowdown/i)) {
      event.preventDefault();
      event.stopPropagation();

      // @ts-ignore
      const nextElement = actionsRef.current[i]?.nextElementSibling;

      if (nextElement) {
        nextElement.focus();
      } else {
        focusFirstElement();
      }
    } else if (key.match(/arrowup/i)) {
      event.preventDefault();
      event.stopPropagation();

      // @ts-ignore
      const previousSibling = actionsRef.current[i]?.previousElementSibling;

      if (previousSibling) {
        previousSibling.focus();
      } else {
        focusLastElement();
      }
    } else if (key.match(/home/i)) {
      event.preventDefault();
      focusFirstElement();
    } else if (key.match(/end/i)) {
      event.preventDefault();
      focusLastElement();
    } else if (key.match(/tab/i)) {
      if (event.shiftKey) {
        // @ts-ignore
        const previousSibling = actionsRef.current[i]?.previousElementSibling;

        if (!previousSibling) {
          onClose();
        }
      } else {
        // @ts-ignore
        const nextElement = actionsRef.current[i]?.nextElementSibling;
        if (!nextElement) {
          onClose();
        }
      }
    }
  };

  const showActionIcons = actions.some((action) => action.icon);

  return (
    <Popover anchor={anchor} ref={popoverRef} anchorPosition={anchorPosition}>
      <Container ref={dropdownRef} role="menu" id={dropdownId}>
        {actions.map((action, i) => {
          const isSelected =
            selectedActionName && selectedActionName === action.name;
          if (action.type === "href")
            return (
              <ActionRow
                key={i}
                // @ts-ignore
                ref={(el) => (actionsRef.current[i] = el)}
                role="menuitem"
                tabIndex={0}
                data-tracker={action.dataTracker}
                style={{
                  padding: 0,
                }}
                onClick={() => close(action.callback)}
                onKeyDown={(event: any) => onKeyDown(event, action, i)}
                selected={isSelected}
              >
                <Link href={action.url} tabIndex={-1}>
                  {showActionIcons && (
                    <RowIconWrapper>
                      {action.icon &&
                        createElement(action.icon, {
                          width: "20px",
                          height: "20px",
                        })}
                    </RowIconWrapper>
                  )}
                  <RowText>{action.name}</RowText>
                </Link>
              </ActionRow>
            );
          if (action.type === "group")
            return (
              <GroupRow key={i}>
                <GroupTitle>{action.name}</GroupTitle>
                {action.items?.map((subAction, j) => {
                  const isSubSelected =
                    selectedActionName && selectedActionName === subAction.name;

                  return (
                    <ActionRow
                      key={j}
                      // @ts-ignore
                      ref={(el) => (actionsRef.current[j] = el)}
                      role="menuitem"
                      tabIndex={0}
                      data-tracker={subAction.dataTracker}
                      onClick={() =>
                        subAction.callback && close(subAction.callback)
                      }
                      onKeyDown={(event: any) => onKeyDown(event, subAction, j)}
                      selected={isSubSelected}
                    >
                      <RowIconWrapper>
                        {subAction.icon &&
                          createElement(subAction.icon, {
                            width: "20px",
                            height: "20px",
                          })}
                      </RowIconWrapper>
                      <RowText>
                        {subAction.name}
                      </RowText>
                    </ActionRow>
                  );
                })}
              </GroupRow>
            );
          return (
            <ActionRow
              key={i}
              // @ts-ignore
              ref={(el) => (actionsRef.current[i] = el)}
              role="menuitem"
              tabIndex="0"
              data-tracker={action.dataTracker}
              onClick={() => close(action.callback)}
              onKeyDown={(event: any) => onKeyDown(event, action, i)}
              selected={isSelected}
            >
              {showActionIcons && (
                <RowIconWrapper>
                  {action.icon &&
                    createElement(action.icon, {
                      width: "20px",
                      height: "20px",
                    })}
                </RowIconWrapper>
              )}
              <RowText>{action.name}</RowText>
            </ActionRow>
          );
        })}
      </Container>
    </Popover>
  );
};

const RowIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--color-primary-text-on-light);
  flex-shrink: 0;
  margin-right: var(--space-sm);
  width: 28px;
`;

const RowText = styled(TextZeta)`
  color: var(--color-primary-text-on-light);
`;

const Link = styled.a`
  display: flex;
  flex-grow: 1;
  text-decoration: none;
  padding: var(--space-sm) var(--space-md);
  border: 1px solid transparent;
  outline: 0;

  &:focus {
    border-color: var(--color-focus-indicator);
  }
`;

const GroupRow = styled.div`
  &:not(:last-child) {
    &:after {
      content: "";
      display: block;
      margin: 8px 0;
      border-bottom: 1px solid var(--color-divider);
    }
  }
`;
const GroupTitle = styled(TextEta)`
  padding: var(--space-sm) var(--space-md);
  color: var(--color-secondary-text-on-light);
  font-weight: 500;
`;

export default forwardRef(DropdownActionMenu);
